<template>
	<div id="groupList">
		<div class="el-content">
			<router-link to="/admin/perm/role_add">
				<a-button type="primary" v-has="{ action: '/admin/perm/role_add' }"><i class="ri-add-line ri-top"></i>添加角色</a-button>
			</router-link>
			<a-table
				:data-source="info.list"
				:pagination="false"
				size="middle"
				rowKey="id"
				style="margin: 12px 0;"
				:columns="[
					{ dataIndex: 'id', key: 'id', title: 'ID' },
					{ dataIndex: 'title', key: 'title', title: '名称' },
					{ dataIndex: 'user_name', key: 'user_name', title: '创建者' },
					{ dataIndex: 'create_time', key: 'create_time', title: '创建时间' },
					{ dataIndex: 'update_time', key: 'update_time', title: '最后更新时间' },
					{ dataIndex: 'action', key: 'action', title: '操作', slots: { customRender: 'action' },}
				]"
			>
				<template #action="{ record }">
					<a-space>
						<router-link :to="{path:'/admin/perm/role_add',query:{id:record.id}}">
							<a-button size="small" type="link" v-has="{ action:'/admin/perm/role_add' }">编辑</a-button>
						</router-link>
						<a-button size="small" type="link" v-has="{ action: 'sass_role_del' }" @click="deleteRole(record.id)">删除</a-button>
					</a-space>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination 
				show-size-changer 
				:default-current="info.page" 
				:total="info.count" 
				@showSizeChange="(page,e)=>{ getRoleList(info.page, e); }"
				@change="e=>{getRoleList(e, info.limit);}"/>
			</div>
		</div>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import authModel from '@/api/saas/auth';
export default {
	setup(){
		const state = reactive({
			info: {
				list: [],
				page: 1,
				count: 0,
				limit: 10
			}
		})
		getRoleList(1,state.info.limit)

		function getRoleList(page,limit){
			authModel.getRole(page,limit,res=>state.info = {limit,...res})
		}

		const deleteRole = id=>authModel.deleteRole(id,()=>getRoleList(state.info.page,state.info.limit))
		return{
			...toRefs(state),
			getRoleList,
			deleteRole
		}
	}
}
</script>

<style></style>
